<template>
  <y-layout-body-menu aside-width="8rem" :menu="[
    {title:'空码',path:'/card/plugin/wifi/hollow'},
    {title:'wifi码',path:'/card/plugin/wifi/manage'},
    {title:'wifi码模版',path:'/card/plugin/wifi/tpl'},
    {title:'基础配置',path:'/card/plugin/wifi/conf'},
  ]">
    <router-view/>
  </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu}
}
</script>

<style scoped>

</style>